import { APP_ID, ApplicationConfig, DEFAULT_CURRENCY_CODE, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { routes } from './app.routes';
import { provideRouter, withRouterConfig } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi, withJsonpSupport, withRequestsMadeViaParent } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from '../pages/pages.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { HttpErrorHandler } from '../providers/httphandler.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      //withDebugTracing(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: "always"
      })
    ),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withJsonpSupport(),
      withInterceptorsFromDi()
    ),
    importProvidersFrom(
      BrowserAnimationsModule,
      PagesModule
      ),
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: "CZK"
    },
    {
      provide: APP_ID,
      useValue: 'serverApp'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorHandler,
      multi: true
    }
  ]
};
